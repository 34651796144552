import React, { useState } from 'react';
import MarkerStyle from './pin.svg';

const markerURL = `${process.env.REACT_APP_URL}/wp-content/themes/feinkost/public/images/pin.svg`;

const styleMarker = {
  position: 'absolute',
  top: '100%',
  left: '50%',
  transform: 'translate(-50%, -100%)',
};

const MyMarker = (props) => {
  const {setOneAccord, setTwoAccord} = props;
  const id = props.id;
  const openAccord = () => {
    setOneAccord(true);
    setTwoAccord(true);
  }
  return (
    <>
      {props.isOpen.id === id ? (
      <div>
          <img
            style={styleMarker}
            src={markerURL}
            className="marker-pin"
            alt="pin"
          />
          {props.isOpen.id === id && (
            <div className="infobox">
              <svg
                height="512px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                width="512px"
                onClick={() => props.setStarLocalization({})}
              >
                <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
              </svg>
              <p>
                <strong>{props.markt}</strong>
              </p>
              <p>
                {props.strase} - {props.ort} {props.city}
              </p>
              <a href={"#" + props.id} onClick={() => openAccord()}>Siehe auf der Liste</a>            </div>
          )}
        </div>
      ) : (
        <div onClick={() => props.setStarLocalization({id: props.id, lng: props.lng, lat: props.lat})}>
          <img
            style={styleMarker}
            src={markerURL}
            className="marker-pin"
            alt="pin"
          />
          {props.isOpen.id === id && (
            <div className="infobox">
              <svg
                height="512px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                width="512px"
                onClick={() => props.setStarLocalization({})}
              >
                <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
              </svg>
              <p>
                <strong>{props.markt}</strong>
              </p>
              <p>
                {props.strase} - {props.ort} {props.city}
              </p>
              <a href={"#" + props.id} onClick={() => openAccord()}>Siehe auf der Liste</a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MyMarker;
