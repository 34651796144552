import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import Map from "./components/Map";
import List from './components/List';

const baseURL = `${process.env.REACT_APP_URL}/wp-json/wp/v2/localization?per_page=300&orderby=date&order=asc`;


const App = () => {
  const [starLocalization, setStarLocalization] = useState(false);

  const [localizations, setLocalizations] = useState(null);
  const [firstAccord, setFirstAccord] = useState(false);
  const [twoAccord, setTwoAccord] = useState(false);
  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setLocalizations(response.data);
    });
  }, []);


  return (
    <>
        <Map starLoc={starLocalization} localizations={localizations} setStarLocalization={setStarLocalization} firstAccordInfo={firstAccord} firstAccordSet={setFirstAccord} twoAccordInfo={twoAccord} twoAccordSet={setTwoAccord} />
        <List starLoc={starLocalization} setStarLoc={setStarLocalization} firstAccordInfo={firstAccord} firstAccordSet={setFirstAccord} twoAccordInfo={twoAccord} twoAccordSet={setTwoAccord} />
    </>
  );
}

export default App;
