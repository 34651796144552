import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useGeolocated } from 'react-geolocated';
import { styles } from './styles';
import MyMarker from './Marker';

const Map = (props) => {
  const { setStarLocalization, localizations, starLoc } = props;
  const { firstAccordSet, twoAccordSet } = props;
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const [defaultCord, setDefaultCord] = useState({
    center: { lat: 50.98478214501527, lng: 11.029865194960584 },
    zoom: 12,
  });

  const deletedGoogleFonts = () => {
    var head = document.getElementsByTagName('head')[0];
    // Save the original method
    var insertBefore = head.insertBefore;

    // Replace it!
    head.insertBefore = function (newElement, referenceElement) {
      if (
        newElement.href &&
        newElement.href.indexOf(
          'https://fonts.googleapis.com/css?family=Google+Sans'
        ) === 0
      ) {
        return;
      }

      insertBefore.call(head, newElement, referenceElement);
    };
  };

  const deletedGoogleFontsRoboto = () => {
    var head = document.getElementsByTagName('head')[0];
    // Save the original method
    var insertBefore = head.insertBefore;

    // Replace it!
    head.insertBefore = function (newElement, referenceElement) {
      if (
        newElement.href &&
        newElement.href.indexOf(
          'https://fonts.googleapis.com/css?family=Roboto'
        ) === 0
      ) {
        return;
      }

      insertBefore.call(head, newElement, referenceElement);
    };
  };


  const checkLocalStorage = (element) => {
    if (localStorage.getItem('popup') !== 'true') {
      addedDivToMap(element);
    }
  };

  const addedDivToMap = (element) => {
    let title = 'Möchten Sie diese Karte wirklich anzeigen? ';
    let description =
      'Durch das Anzeigen dieser Karte stimmen Sie der Datenschutzerklärung von Google Maps zu. <br/><br/> <a href="https://policies.google.com/privacy?hl=dehttps://www.google.com/help/legalnotices_maps/" target="_blank" style="color: white;">Erfahren Sie mehr darüber.</a>';
      let buttonTitle = 'Ja, ich stimme zu.';

    let div = document.createElement('div');
    let divTwo = document.createElement('div');
    let textHeading = document.createElement('h5');
    let text = document.createElement('p');
    let button = document.createElement('button');

    button.innerHTML = buttonTitle;
    button.classList.add('close-button-google');

    textHeading.innerHTML = title;
    text.innerHTML = description;

    div.classList.add('google-maps-popup');

    divTwo.classList.add('google-maps-popup__wrapper');
    divTwo.appendChild(textHeading);
    divTwo.appendChild(text);
    divTwo.appendChild(button);

    div.appendChild(divTwo);
    if(element){
      element.appendChild(div);
    }

    button.addEventListener('click', () => {
      localStorage.setItem('popup', 'true');
      div.innerHTML = '';
      div.classList.remove('google-maps-popup');
    });
  };

  useEffect(() => {
    deletedGoogleFonts();
    deletedGoogleFontsRoboto();
    if(document.querySelector('.google-maps-popup')){
    } else {
      checkLocalStorage(document.querySelector('.google-map > div'));
    }
  }, [deletedGoogleFonts, deletedGoogleFontsRoboto, checkLocalStorage]);

  if (!localizations) return null;

  return (
    <div
      style={{ height: '550px', width: '100%' }}
      className="google-map"
      id="map-google"
    >
      {(function () {
        if (
          (!coords && starLoc === false) ||
          Object.keys(starLoc).lenght === 0
        ) {
          return (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCW8XmU7JnkiwSP2gmJgMQAE2PS3yDzpEk',
              }}
              defaultCenter={defaultCord.center}
              defaultZoom={12}
              options={{ styles: styles }}
            >
              {localizations.map((loc, index) => (
                <MyMarker
                  key={index}
                  id={loc.id}
                  lat={loc.acf.lat}
                  lng={loc.acf.lng}
                  name={loc.acf.name}
                  markt={loc.acf.markt}
                  strase={loc.acf.strase}
                  ort={loc.acf.ort}
                  city={loc.acf.city}
                  isOpen={starLoc}
                  setStarLocalization={setStarLocalization}
                  setOneAccord={firstAccordSet}
                  setTwoAccord={twoAccordSet}
                />
              ))}
            </GoogleMapReact>
          );
        } else if (starLoc !== false && Object.keys(starLoc).lenght !== 0) {
          return (
            <>
              <span style={{ display: 'none' }}>Google map</span>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyCW8XmU7JnkiwSP2gmJgMQAE2PS3yDzpEk',
                }}
                // defaultCenter={{
                //   lat: Number(starLoc.lat),
                //   lng: Number(starLoc.lng),
                // }}
                center={{
                  lat: Number(starLoc.lat),
                  lng: Number(starLoc.lng),
                }}
                defaultZoom={12}
                options={{ styles: styles }}
              >
                {localizations.map((loc, index) => (
                  <MyMarker
                    key={index}
                    id={loc.id}
                    lat={loc.acf.lat}
                    lng={loc.acf.lng}
                    name={loc.acf.name}
                    markt={loc.acf.markt}
                    strase={loc.acf.strase}
                    ort={loc.acf.ort}
                    city={loc.acf.city}
                    isOpen={starLoc}
                    setStarLocalization={setStarLocalization}
                    setOneAccord={firstAccordSet}
                    setTwoAccord={twoAccordSet}
                  />
                ))}
              </GoogleMapReact>
            </>
          );
        } else {
          return (
            <>
              <span style={{ display: 'none' }}>Google map</span>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyCW8XmU7JnkiwSP2gmJgMQAE2PS3yDzpEk',
                }}
                defaultCenter={{ lat: coords.latitude, lng: coords.longitude }}
                center={{ lat: coords.latitude, lng: coords.longitude }}
                defaultZoom={12}
                options={{ styles: styles }}
              >
                {localizations.map((loc, index) => (
                  <MyMarker
                    key={index}
                    id={loc.id}
                    lat={loc.acf.lat}
                    lng={loc.acf.lng}
                    name={loc.acf.name}
                    markt={loc.acf.markt}
                    strase={loc.acf.strase}
                    ort={loc.acf.ort}
                    city={loc.acf.city}
                    isOpen={starLoc}
                    setStarLocalization={setStarLocalization}
                    setOneAccord={firstAccordSet}
                    setTwoAccord={twoAccordSet}
                  />
                ))}
              </GoogleMapReact>
            </>
          );
        }
      })()}
    </div>
  );
};

export default Map;
