import React from 'react';
import './styles.scss';
import { Col } from 'reactstrap';

const Item = ({ id, name, lng, lat, strase, ort, city, isOpen, setIsOpen }) => {
  const openMap = () => {
    setIsOpen({id: id, lng: lng, lat: lat});
    const section = document.querySelector( '#map-google' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  }
  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <>
      {id === isOpen.id ? (
        <Col className="item active" md="6" lg="4" id={id}>
          <h3>{renderHTML(name)}</h3>
          <p>
            {strase} - {ort} {city}
          </p>
          <hr />
        </Col>
      ) : (
        <Col className="item" md="6" lg="4" id={id} onClick={() => openMap()}>
          <h3>{renderHTML(name)}</h3>
          <p>
            {strase} - {ort} {city}
          </p>
          <hr />
        </Col>
      )}
    </>
  );
};

export default Item;
