import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.scss';

import { Row } from 'reactstrap';

import Item from './Item';

const baseURL = `${process.env.REACT_APP_URL}/wp-json/wp/v2/localization?per_page=300&orderby=date&order=asc`;

const List = (props) => {
  const {firstAccordInfo, firstAccordSet, twoAccordInfo, twoAccordSet} = props;
  const [listItem, setListItem] = useState([]);
  const [loading, setLoading] = useState(false);

  const changeAccord = () => {
    if (firstAccordInfo === true) {
      firstAccordSet(false);
    } else {
      firstAccordSet(true);
    }
  };

  const changeAccordTwo = () => {
    if (twoAccordInfo === true) {
      twoAccordSet(false);
    } else {
      twoAccordSet(true);
    }
  };

  useEffect(() => {
    axios
      .get(baseURL)
      .then((response) => {
        setListItem(response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Data is loading...</p>;
  }

  const datas = listItem.data;
  if (!datas) return null;

  const array1 = datas.slice(0, 30);
  const array2 = datas.slice(30);
  
  return (
    <>
      <div className="d-flex mt-4 mb-5">
        <button className="button-open m-auto" onClick={changeAccord}>
          {firstAccordInfo === true ? 'LISTE SCHLIEßEN' : 'IN MEINER NÄHE'}
        </button>
      </div>
      {firstAccordInfo === true && (
        <Row className="list-wrapper">
          {array1.map((data, index) => (
            <Item  key={index} id={data.id} lat={data.acf.lat} lng={data.acf.lng}  name={data.acf.markt} city={data.acf.city} markt={data.acf.city} ort={data.acf.ort} strase={data.acf.strase} isOpen={props.starLoc} setIsOpen={props.setStarLoc} />
          ))}
          <div className="d-flex button-wrapper">
            <button
              className="button-read-more m-auto"
              onClick={changeAccordTwo}
            >
              {twoAccordInfo === true ? 'Zeige weniger' : 'Zeig mehr'}

              <svg
                version="1.1"
                id="Layer_1"

                x="0px"
                y="0px"
                viewBox="0 0 330 330"
              >
                <path
                  id="XMLID_225_"
                  d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                ></path>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </button>
          </div>
          {twoAccordInfo === true && (
            <Row>
              {array2.map((data, index) => (
                <Item key={index} id={data.id} lat={data.acf.lat} lng={data.acf.lng} name={data.acf.markt} city={data.acf.city} markt={data.acf.city} ort={data.acf.ort} strase={data.acf.strase} isOpen={props.starLoc} setIsOpen={props.setStarLoc} />
              ))}
            </Row>
          )}
        </Row>
      )}
    </>
  );
};

export default List;
